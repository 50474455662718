@keyframes blink {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.2;
    }
}

html {
    background: #f5f5f5;
}

body, html {
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}
#root {
    min-height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}
#root {
    display: flex;
    flex-direction: column
}